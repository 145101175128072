@import '../custom-theme.css';

:root {
  --global-text-color: rgba(255, 255, 255, 0.85);
  --global-main-color: var(--antd-wave-shadow-color);
  --global-second-color: #FFF;
  --global-main-color-darken: #242831;
  --global-calendar-header-colors: #1c212d;
  --global-calendar-border-colors: #7b7b7b;
}




body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //color: rgba(255, 255, 255, 0.85);
  //background-color: #141414;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dynamic-delete-button {
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: red;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}


.ql-snow .ql-stroke {
  stroke: rgba(255,255,255,0.85) !important;
}

.ql-snow button:hover .ql-stroke {
  stroke: var(--global-second-color) !important;
}

.ql-snow .ql-picker {
  color: rgba(255,255,255,0.85) !important;
}

.ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: var(--global-second-color) !important;
}

.ql-snow .ql-picker-options {
  background-color: #1f1f1f !important;
}

.ql-container.ql-snow {
  border: 1px solid var(--global-calendar-border-colors) !important;
}

.ql-toolbar.ql-snow {
  border: 1px solid var(--global-calendar-border-colors) !important;;
}



.action-button-table {
  font-size: 1.2rem;
}

.action-button-table.action-button-selected {
  color: var(--global-main-color);
}

.action-button-table.action-button-selected-danger {
  color: red;
}

.action-button-table:hover {
  color: var(--global-calendar-border-colors);
}


/** REACT CALENDAR TIMELINE **/

.react-calendar-timeline .rct-sidebar {
  border-color: var(--global-calendar-border-colors) !important;
}
.react-calendar-timeline .rct-sidebar.rct-sidebar-right {
  border-color: var(--global-calendar-border-colors) !important;
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
  border-color: var(--global-calendar-border-colors) !important;
  background-color: var(--global-calendar-header-colors) !important;
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
  /*background: rgba(255, 255, 255, 0.05) !important;*/
}

.react-calendar-timeline .rct-vertical-lines .rct-vl {
  border-color: var(--global-calendar-border-colors) !important;
}
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6, .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
  background: inherit !important;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  border-color: var(--global-calendar-border-colors) !important;
}
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  background: rgba(255, 255, 255, 0.05) !important;
}
.react-calendar-timeline .rct-horizontal-lines .rct-hl-even {
  background: transparent;
}

.react-calendar-timeline .rct-cursor-line {
  background: var(--global-main-color) !important;
}

.react-calendar-timeline .rct-dateHeader {
  border-color: var(--global-calendar-border-colors) !important;
  background-color: var(--global-calendar-header-colors) !important;
  border-width: 1px !important;
}

.react-calendar-timeline .rct-dateHeader-primary {
  border-color: var(--global-calendar-border-colors) !important;
  color: #fff;
}

.react-calendar-timeline .rct-header-root {
  background: inherit !important;
  border-color: var(--global-calendar-border-colors) !important;
}

.react-calendar-timeline .rct-calendar-header {
  border-color: var(--global-calendar-border-colors) !important;
}
.react-calendar-timeline .rct-scroll {
  overflow-x: hidden !important;
}


/** REACT BIG CALENDER **/

.rbc-off-range {
  color: #999999;
}

.rbc-off-range-bg {
  background: var(--global-calendar-header-colors) !important;
}

.rbc-header {
  border-color: var(--global-calendar-border-colors) !important;
  background-color: var(--global-calendar-header-colors) !important;
  padding: 20px !important;
}
.rbc-header + .rbc-header {
  border-color: var(--global-calendar-border-colors) !important;
}
.rbc-rtl .rbc-header + .rbc-header {
  border-color: var(--global-calendar-border-colors) !important;
}


.rbc-today {
  background-color: rgba(255,255,255,0.15) !important;
}

.rbc-toolbar button {
  color: var(--global-text-color) !important;

  border: 1px solid var(--global-calendar-border-colors) !important;

}
.rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
  color: #373a3c !important;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  background-color: #e6e6e6;
  border-color: #adadad;
}
.rbc-toolbar button:active:hover, .rbc-toolbar button:active:focus, .rbc-toolbar button.rbc-active:hover, .rbc-toolbar button.rbc-active:focus {
  color: #373a3c !important;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
.rbc-toolbar button:focus {
  color: #373a3c !important;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.rbc-toolbar button:hover {
  color: #373a3c !important;
  background-color: #e6e6e6;
  border-color: #adadad;
  cursor: pointer;
}

.rbc-event, .rbc-day-slot .rbc-background-event {
  background-color: var(--global-second-color) !important;
  color: var(--global-calendar-header-colors) !important;
}


.rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: var(--global-second-color) !important;
}
.rbc-event:focus, .rbc-day-slot .rbc-background-event:focus {
  outline: 5px auto  var(--global-second-color) !important;
}


.rbc-event:hover, .rbc-day-slot .rbc-background-event:hover {
  background-color: var(--global-main-color) !important;
}

.rbc-show-more {
  background-color: rgba(255, 255, 255, 0.3);

}

.rbc-month-view {
  border-color: var(--global-calendar-border-colors) !important;
}

.rbc-month-row + .rbc-month-row {
  border-color: var(--global-calendar-border-colors) !important;
}

.rbc-day-bg + .rbc-day-bg {
  border-color: var(--global-calendar-border-colors) !important;
}
.rbc-rtl .rbc-day-bg + .rbc-day-bg {
  border-color: var(--global-calendar-border-colors) !important;
}

.rbc-overlay {
  border: 1px solid #e5e5e5;
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
}

.rbc-overlay-header {
  border-bottom: 1px solid #e5e5e5;
}

.rbc-agenda-view table.rbc-agenda-table {
  border-color: var(--global-calendar-border-colors) !important;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-color: var(--global-calendar-border-colors) !important;
}
.rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left-width: 0;
  border-color: var(--global-calendar-border-colors) !important;
}
.rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
  border-color: var(--global-calendar-border-colors) !important;
}
.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  border-color: var(--global-calendar-border-colors) !important;
}

.rbc-timeslot-group {
  border-color: var(--global-calendar-border-colors) !important;
}

.rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event {
  border: 1px solid var(--global-second-color) !important;
}
.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid rgba(255,255,255,0.1) !important;
}

.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
  background-color: white;
  border-color: var(--global-calendar-border-colors) !important;
}

.rbc-slot-selection {

  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}

.rbc-time-view {
  border-color: var(--global-calendar-border-colors) !important;
}
.rbc-time-view .rbc-allday-cell + .rbc-allday-cell {
  border-color: var(--global-calendar-border-colors) !important;
}
.rbc-time-header.rbc-overflowing {
  border-color: var(--global-calendar-border-colors) !important;
}
.rbc-rtl .rbc-time-header.rbc-overflowing {
  border-right-width: 0;
  border-color: var(--global-calendar-border-colors) !important;
}
.rbc-time-header > .rbc-row:first-child {
  border-color: var(--global-calendar-border-colors) !important;
}
.rbc-time-header > .rbc-row.rbc-row-resource {
  border-color: var(--global-calendar-border-colors) !important;
}

.rbc-time-header-content {
  border-color: var(--global-calendar-border-colors) !important;
}
.rbc-rtl .rbc-time-header-content {
  border-left-width: 0;
  border-color: var(--global-calendar-border-colors) !important;
}
.rbc-time-header-content > .rbc-row.rbc-row-resource {
  border-color: var(--global-calendar-border-colors) !important;
}

.rbc-time-content {
  border-color: var(--global-calendar-border-colors) !important;
}
.rbc-time-content > * + * > * {
  border-color: var(--global-calendar-border-colors) !important;
}
.rbc-rtl .rbc-time-content > * + * > * {
  border-color: var(--global-calendar-border-colors) !important;
}

.rbc-current-time-indicator {
  height: 2px !important;
  background-color: red !important;
}

/*# sourceMappingURL=react-big-calendar.css.map */

.ant-picker-calendar,
.ant-picker-calendar .ant-picker-panel {
  background: #1f1f1f;
}


.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}




/* Booking detail inline calender */
.calendar-header-arrow {
  cursor: pointer;
  user-select: none;
}
.calendar-header-arrow:hover {
  color: var(--global-main-color);
}


/** SHIFT PLAN **/

.shift-box {
  user-select: none;
  padding: 10px 15px;
  border-radius: 2px;
  color: #1d1d1d;
  height: 65px;
  border: 5px solid transparent;
  text-wrap: nowrap;
}
.shift-box.clickable {
  cursor: pointer;
}




.shift-box.WORK {
  background-color: #d4d5d7;
  border-color: #d4d5d7;
}
.shift-box.ILLNESS {
  background-color: #9edfff;
  border-color: #9edfff;
}
.shift-box.HOLIDAY {
  background-color: #9edfff;
  border-color: #9edfff;
}

.shift-box.draft {
  border-color: orange !important;
}

.shift-box.draft-deleted {
  border-color: red !important;
  opacity: 0.5;
}

.shift-box.deleted {
  opacity: 0.5;
  background-color: #ff9e9e;
  border-color: #ff9e9e;
}

.shift-box.clickable:hover {
  opacity: 0.8;
}

.shift-box.clickable:active {
  opacity: 0.6;
}

.shift-plan-table tr:nth-child(even) { background-color: rgba(255,255,255,0.05); }

.shift-row {
  height: 100px;
}

.shift-row > td {
  text-align: center;
  border-right: 2px solid rgba(255,255,255,0.15);
  //border-bottom: 2px solid rgba(255,255,255,0.05) !important;
}

.shift-row > td:hover .shift-plus-box {
  visibility: visible;
}

.shift-row > td:first-child {
  text-align: left;
}

.shift-plus-box {
  user-select: none;
  padding: 10px 35px;

  visibility: hidden;
  cursor: pointer;
  border-radius: 2px;
  border: 1px solid rgba(255,255,255,0.15);
  height: 65px;
  align-items: center;
  text-align: center;
  display: flex;
  flex: 1;
}

.shift-plus-box:hover {
  user-select: none;
}

.shift-draft-banner {
  box-sizing: border-box;
  z-index: 9;
  background-color: rgb(255, 153, 0);
  color: white;
  padding: 15px;
  border-radius: 2px;
}

.shift-draft-banner .ant-btn-primary{
    color: black;
    background-color: white;
    border-color: white;
}

.shift-draft-banner .ant-btn-primary:hover {
  color: black;
  background-color: #aaaaaa;
  border-color: #aaaaaa;
}

.shift-draft-banner .ant-btn-primary:active {
  color: black;
  background-color: #7e7e7e;
  border-color: #7e7e7e;
}

.shift-draft-banner.live {
  background-color: rgb(0, 119, 255);
}

.shift-draft-banner a.ant-typography {
  color: white;
}

.shift-draft-banner a.ant-typography:hover {
  color: #cdcccc;
}

.shift-draft-banner a.ant-typography:active {
  color: white;
}