.app-main-content {
    background-color: #000000;
    background-image: linear-gradient(315deg, #2b303b 0%, #2e3440 74%);
    min-height: 100vh;
    padding: 24px 16px 0;
    overflow: hidden;
}

.ant-layout-sider-children {
    display: flex;
    flex-direction: column;
}

.ant-layout-sider-children > .ant-menu {
    margin-bottom: 100px;
    overflow-y: auto;
    overflow-x: hidden;
}

.logo {
    text-align: center;
    padding: 35px 25px 15px 25px;
    box-sizing: content-box;
}


.sidebar-bottom {
    position: absolute;
    bottom: 0px;
    padding: 15px;
    width: 100%;
}

.table-clickable{
    cursor: pointer;
}

.table-row-disabled {
    color: var(--global-calendar-border-colors) !important;
}


.sidebar-user-wrapper {
    width: 100%;
    border-radius: 5px;
    background-color: var(--global-main-color-darken);
    padding: 15px;
    cursor: pointer; }
.sidebar-user-wrapper:hover {
    background-color: var(--global-main-color); }
.sidebar-user-wrapper .sidebar-user-search-icon,
.sidebar-user-wrapper .sidebar-user-icon {
    padding: 0px 15px;
    user-select: none; }
.sidebar-user-wrapper .sidebar-user {
    cursor: pointer;
    user-select: none;
    display: inline; }
.sidebar-user-wrapper .sidebar-user .sidebar-user-avatar {
    background-color: #141414;
    color: rgba(255, 255, 255, 0.38);
    box-shadow: 0 2px 15px rgba(50, 50, 50, 0.1);
    vertical-align: middle;
    margin-right: 10px;
}
.sidebar-user-wrapper .sidebar-user .sidebar-username {
    padding-left: 15px; }

.sidebar-user-menu {
    width: 208px; }
.sidebar-user-menu .user-menu-row {
    display: flex;
    cursor: pointer;
    padding: 5px; }
.sidebar-user-menu .user-menu-row:hover {
    color: var(--global-main-color);
}
.sidebar-user-menu .user-menu-row .user-menu-icon {
    flex: 30px;
    width: 30px; }
.sidebar-user-menu .user-menu-row .user-menu-text {
    flex: 100%;
    width: 100%; }

.commentRight .ant-comment-avatar {
    margin-left: 12px
}

.commentRight .ant-comment-inner {
    flex-direction: row-reverse;
    text-align: right;
    float: right;
}
