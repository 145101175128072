
.login-container {
    min-height: 100vh;
}

.login-image {
    background-image: url('background.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    height: 100%;
}

.form-wrapper {
    margin-top: 25px;
}

.login-content {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 70px;
}

.login-btn {
    width: 100%;
    margin-top: 50px;
    //background: linear-gradient(145deg, var(--global-main-color), var(--global-second-color)) !important;
}
.login-btn:hover {
    //background: linear-gradient(145deg, #03BAFF, #4A8AFE);
    opacity: 0.8;
}

.login-H1 {
    font-size: 2.5em;
    opacity: 0.5;
    margin-top: 50px;
}

.login-H2 {
    font-size: 3em;
    position: relative;
    margin-bottom: 50px;
}

.login-H2:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 50px;
    height: 3px;
    background: var(--global-main-color);
}
